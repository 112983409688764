@import '../styles/variables.scss';

.content {
  display: flex;
  gap: 1rem;
  padding: 2rem;
}
.addbutton {
  font-size: small;
}

.input {
  font-family: 'eina03semiBold';
  padding-top: $spacing-xs;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.hide {
  display: none;
}
.buttonDisplay {
  display: flex;
  gap: $spacing-sm;
  justify-content: flex-end;
}
.optionSet {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.hide {
  display: none;
}
.checkBoxDisplay {
  margin-bottom: $spacing-md;
  padding: $spacing-sm;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}
.optionDisplay {
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .optionInput {
    width: calc(80vw - 8rem);
  }
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-sm;
}

.deselectIcon:hover {
  cursor: pointer;
}
