@import '../../styles/variables.scss';

.pdfButton {
  width: 100%;
}
.check {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.subCheck {
  @extend .check;
  padding-left: $spacing-xs;
  padding-top: $spacing-xs;
}
.checkContainer {
  margin: $spacing-xs;
}
.flexRow {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
