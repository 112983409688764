@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $spacing-sm;
  min-height: 4rem;
  cursor: pointer;
}

.highlight {
  border: $border-red;
  border-radius: $border-radius-md;
  padding: $spacing-xs;
  margin-bottom: $spacing-sm;
}

.icon {
  color: $text-secondary;
  opacity: 0.25;
}

.rightContainer,
.leftContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
}

.deleteIcon {
  color: $red;
  display: block;
  margin-left: auto;
}

.expandIcon {
  color: $text-secondary;
}

.expandIcon:hover,
.deleteIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.actionList {
  margin: $spacing-sm;
  padding: $spacing-sm;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  background-color: $white;
  border-bottom: none;
}
.sectionLine {
  border-top: 0.02rem solid $gray-ultra-light;
  margin: $spacing-xxs 0;
}
.subContainer {
  border-bottom: none !important;
}
.bottomBorder {
  border-bottom: $border-ultra-light;
}
.red {
  color: $red;
}
