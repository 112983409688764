@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  border-radius: 8px;
  min-height: 4.5rem;
  min-width: 4.5rem;
  height: 4.5rem;
  width: 4.5rem;
  border: 2px solid $accent-primary-translucent;
}

.selectedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  min-height: 4.5rem;
  min-width: 4.5rem;
  height: 4.5rem;
  width: 4.5rem;
  border: 2px solid $accent-primary-translucent;
  background-color: $accent-primary;
}

.selectedContainer:hover {
  background-color: $accent-primary-highlight;
  cursor: pointer;
}

.container:hover {
  color: $accent-primary-dark;
  background-color: white;
  cursor: pointer;
}

.title {
  color: $text-primary;
  display: block;
  text-align: center;
  margin: 0px;
  font-size: $font-size-sm;
  user-select: none;
}

.selectedTitle {
  @extend .title;
  color: $white;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  color: $accent-primary;
  font-size: 1.5rem;
}

.selectedIcon {
  @extend .icon;
  color: $white;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
  .selectedContainer {
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
}
