@import '../../styles/variables.scss';

.container {
  background-color: white;
  padding: $spacing-md;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
