@import '../../styles/variables.scss';

.split {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fixedFooter {
  position: fixed;
  background-color: $white;
  bottom: 0;
  padding: 0 $spacing-lg;
  padding-bottom: $spacing-xs;
  width: calc(100% - $sidebar-width);
  transform: translateX(-$spacing-md);
  z-index: 1000;
  height: fit-content;
}
.marginLeft {
  width: 100%;
  margin-left: auto;
}

.bottomSpace {
  height: 10rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: $spacing-sm;
}
.actionItems {
  margin: 0 $spacing-sm;
}
.actionItemList {
  margin-top: $spacing-sm;
}
.flex {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.sigInput {
  background-color: $white;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-radius-md;
}
.paddingSm {
  padding: $spacing-sm;
}
.spacer {
  height: $spacing-sm;
}
.actionItemCard {
  box-shadow: none;
  border: $border-ultra-light;
  margin-top: $spacing-sm;
}

.cpa {
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  gap: $spacing-sm;
  max-height: 20rem;
  overflow: scroll;
}
.spacer {
  height: $spacing-sm;
}
.cpaLeft {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  max-width: 70%;
}
.cpaOdd {
  @extend .cpa;
  background-color: $white;
}
.bundleQuestion {
  margin-bottom: $spacing-xs;
}

.odd {
  background-color: white;
  padding: $spacing-sm;
  padding-bottom: $spacing-md;
  border-radius: $border-radius-lg;
  min-height: 5rem;
  overflow-y: visible;
}

.even {
  @extend .odd;
  background-color: $white;
}
.oddBundle {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  min-height: 5rem;
  margin-bottom: $spacing-sm;
  width: 100%;
  overflow-y: visible;
}
.evenBundle {
  @extend .oddBundle;
  background-color: $white;
}

.spinner {
  display: block;
  margin: auto;
  height: 3rem;
  width: 3rem;
  margin-top: 10rem;
  margin-bottom: 5rem;
}
.partyImage {
  height: 2rem;
}
.analysisMessage {
  display: flex;
  flex-direction: column;
  padding: $spacing-md;
}

.disclaimer {
  font-family: 'eina03semibold';
}

.displayAnalysis {
  margin: 0 auto;
}
.iconDisplay {
  display: flex;
  gap: $spacing-sm;
}

.container {
  background-color: $white;
  padding: $spacing-md;
  min-height: calc(100vh - $navbar-height - $spacing-lg);
  width: calc(100% - $inspection-sidebar-width);
}

.details {
  display: flex;
  gap: $spacing-sm;
}
.cpaHeader {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.rightTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.vehicleCard {
  border-radius: $border-radius-lg;
  display: flex;
  justify-content: center;
  background-color: white;
  margin: $spacing-md auto;
  width: fit-content;
}
.referenceImages {
  display: flex;
  gap: $spacing-sm;
  padding: $spacing-md 0;
  flex-wrap: wrap;
}
.templateImage {
  width: auto;
  height: 8rem;
  border-radius: $border-radius-lg;
  cursor: pointer;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: $spacing-sm;
  width: 100%;
  margin-top: $spacing-xs;
}
.marginLeft {
  display: flex;
  justify-content: flex-end;
}
.submitButton {
  margin-left: auto;
}

.hideDesktop {
  display: none;
}

.bundleButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $spacing-md;
}

.deleteIcon {
  color: $red;
}
.icon {
  opacity: 0.7;
}

.deleteIcon:hover,
.icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.bundleContainer {
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  gap: $spacing-xs;
  overflow-x: scroll;
}
.bundleContainer {
  display: flex;
  align-items: flex-start;
}
.bundleNumber {
  margin-top: $spacing-sm;
}

.pdfViewer {
  position: absolute;
  width: 40rem;
  height: 80rem;
}
.inspectionItemText {
  margin-bottom: $spacing-sm;
}

@media only screen and (max-width: $mobile-threshold) {
  .container {
    padding: $spacing-sm;
    width: 100%;
  }
  .vehicleCard {
    width: 100%;
  }

  .bottomContainer {
    gap: $spacing-sm;
    width: 100%;
  }
  .middleContainer {
    width: 100%;
  }
  .fixedFooter {
    width: 100%;
    position: unset;
    transform: translateX(-$spacing-sm);
    padding-bottom: $spacing-sm;
  }

  .hide {
    display: none;
  }
  .hideDesktop {
    display: block;
    opacity: 0.5;
  }
  .bottomSpace {
    height: 0;
  }

  .hideDesktop:hover {
    opacity: 0.25;
    cursor: pointer;
  }
  .cpa {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
