@import '../../../styles/variables.scss';

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 2.5rem;
}
.spinner {
  height: $spacing-lg;
  width: $spacing-lg;
}
.commentIcon {
  margin-left: auto;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: $spacing-xxs;
}
.textOverflow {
  max-height: 20rem;
  overflow-y: scroll;
  max-width: 95%;
}
.rightIcons {
  display: flex;
  gap: $spacing-xxs;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}
.transcriptSelector {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  justify-content: center;
}
.failLink {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: auto;
}

.failComponent {
  display: flex;
  gap: $spacing-sm;
  flex-direction: row;
  align-items: flex-start;
  margin: $spacing-xs 0;
}
.failImage {
  max-width: 50%;
  margin-top: $spacing-sm;
  max-height: 50vh;
  border-radius: $border-radius-lg;
}
.left {
  min-width: 8rem;
}
.fileContainer {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.leftContainer {
  min-width: 100%;
}
.fileDisplay {
  margin-top: $spacing-sm;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
  gap: $spacing-sm;
  background-color: white;
  border-radius: $border-radius-md;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
}
.fileDisplay:hover {
  cursor: pointer;
}
.multiUser {
  margin-bottom: $spacing-xs;
}
.fileIcon {
  height: $spacing-lg;
}
.file {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  gap: $spacing-sm;
  background-color: white;
  border-radius: $border-radius-md;
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  margin-bottom: $spacing-sm;
}
.audioCard {
  @extend .card;
}
.audioCard:hover {
  cursor: auto;
}
.viewTranscript {
  margin-top: $spacing-sm;
}

.subHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: $spacing-xs;
  min-width: 80%;
  max-width: 80%;
}
.matrixLabel {
  display: block;
  padding: $spacing-xs $spacing-md;
  border-radius: $border-radius-lg;
  max-width: fit-content;
  margin-top: 1rem;
}

.textResponse {
  margin-top: $spacing-xs;
  max-width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
}

.image {
  max-width: 40vw;
  height: 'auto';
  border-radius: $border-radius-md;
  display: block;
  margin: auto;
  max-height: 50vh;
  margin-bottom: $spacing-xs;
}

.undoIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}

.noneIcon {
  display: none;
}

.passIcon {
  color: $green;
}

.failIcon {
  color: $red;
}
.saveIcon {
  color: $green;
  opacity: 0.5;
}
.audioPlayer {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}
.cancelIcon {
  color: $red;
  opacity: 0.5;
}

.moreIcon:hover {
  cursor: pointer;
}

.userCard {
  margin-top: $spacing-sm;
}
.signature {
  display: flex;
  align-items: center;
}
.testButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-md;
}
.voiceRecorder {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.displayImage {
  display: block;
  max-width: 50%;
  height: auto;
  border-radius: $border-radius-lg;
  margin: 0 auto;
  margin-bottom: $spacing-xs;
  max-height: 10rem;
}

.matrixText {
  display: flex;
  gap: $spacing-sm;
}
.card {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.numberText {
  margin-top: $spacing-xs;
}

.input {
  border: $border;
  border-radius: $border-radius-md;
  padding: $spacing-xs;
  background-color: white;
  font-size: $font-size-md;
  min-width: 100%;
}
.inputContainer {
  display: flex;
  justify-content: space-between;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  padding-left: $spacing-xs;
}

.icon:hover,
.iconContainer:hover {
  cursor: pointer;
  opacity: 0.6;
}
.textInput {
  width: 100%;
}
.tableContainer {
  overflow-x: scroll;
}
.hide {
  display: none;
}
.tableHeader {
  word-wrap: break-word;
}
.spacer {
  height: $spacing-xs;
}
.spacerSm {
  height: $font-size-sm;
}
.failCard {
  padding: $spacing-sm;
  border: $border-red;
  border-radius: $border-radius-lg;
  background-color: white;
  margin-top: $spacing-sm;
}
.failContainer {
  display: flex;
  flex-direction: column;
}
.multiSignatureDisplay {
  margin-bottom: $spacing-xs;
}
.hover {
  cursor: pointer;
}
.fullScreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: $spacing-lg;
  transition: all 0.3s ease;
  background-color: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  border: $border;
}

.tableContainer {
  max-width: 100%;
  overflow-x: scroll;
}
@media only screen and (max-width: $mobile-threshold) {
  .failContainer {
    margin: 0;
  }
  .testButtonContainer {
    flex-wrap: wrap;
    gap: $spacing-sm;
  }
  .editText {
    display: none;
  }
  .closeIcon {
    transform: translateY(-1.5rem);
  }
  .fullScreen {
    padding: $spacing-sm;
  }

  .leftContainer {
    width: 50vw;
  }
  .inputQuestions {
    margin-top: $spacing-sm;
  }
  .displaySignature {
    padding: 1rem;
    max-width: 100%;
  }
  .fileDisplay {
    flex-direction: column;
  }

  .multiSignatureDisplay {
    display: flex;
    flex-direction: column;
  }

  .table {
    table-layout: auto;
    max-width: 100%;
    overflow-x: scroll;
  }
  .multiImage {
    display: flex;
    gap: $spacing-sm;
    max-width: 95%;
    flex-wrap: wrap;
    padding-top: $spacing-sm;
  }
  .sigSingle {
    display: flex;
    gap: $spacing-sm;
    align-items: center;
  }

  .editText {
    display: none;
  }
  td tr {
    word-wrap: break-word;
  }

  .leftContainer {
    width: 100%;
  }
  .displayImage {
    max-width: 40vw;
  }
  .failComponent {
    flex-direction: column;
  }
  .image,
  .failImage {
    max-width: 60vw;
    max-height: 70vh;
  }
  .fileIcon {
    height: $spacing-md;
  }
}
