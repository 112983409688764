@import '../../../styles/variables.scss';

.container {
  padding: $spacing-xs;
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  text-wrap: wrap;
}
.container:hover {
  cursor: pointer;
  opacity: 0.5;
  background-color: $white;
}
.left {
  width: 10%;
}
.text {
  width: 100%;
}
.middle {
  padding-bottom: $spacing-xxs;
  width: 75%;
}
.right {
  width: 7%;
  margin: auto;
  margin-left: auto;
}
.primary {
  color: $accent-primary;
}
.yellow {
  color: $yellow;
}
.green {
  color: $green;
}
.red {
  color: $red-light;
}
.purple {
  color: $purple-light;
}
.pink {
  color: $pink;
}
.secondary {
  color: $text-secondary;
}
.unseen {
  background-color: $white;
}
.unseen:hover {
  cursor: pointer;
  background-color: white;
}
.icon {
  min-height: $font-size-xl;
}
@media only screen and (max-width: $mobile-threshold) {
  .container {
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg;
    background-color: white;
    min-width: 100%;
  }
  .left {
    width: 10%;
  }
  .middle {
    min-width: 80%;
  }
  .right {
    width: 5%;
  }
}
