@import '../../../styles/variables.scss';

.container {
  padding: $spacing-sm;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  background-color: white;
  margin-bottom: $spacing-sm;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xs;
}
.title {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
  padding: 0 $spacing-sm;
}
.content {
  max-height: 25rem;
  overflow-y: scroll;
  padding: $spacing-xs;
}
.links {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  margin: $spacing-xs;
}
.selected {
  border-bottom: $border-blue;
}
