@import '../../../styles/variables.scss';
.topButtons {
  @extend flex-row-space-between;
}
.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  max-width: 40vw;
}
.button {
  width: 48%;
  height: auto;
  border-radius: $border-radius-lg;
}
.file {
  padding: $spacing-sm;
  box-shadow: $box-shadow;
  border-radius: $border-radius-lg;
}

.check {
  display: flex;
  gap: $spacing-xs;
  align-items: center;
}
.images {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
  max-width: 100%;
  overflow-x: scroll;
  align-items: center;
  padding-bottom: $spacing-xs;
}
.header {
  display: flex;
  gap: $spacing-sm;
  align-items: center;
}
.templateQuestions {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}
.team {
  max-width: 50vw;
  margin-bottom: $spacing-sm;
}
.dateSelector {
  padding: $spacing-xs;
  font-weight: 100;
  border: $border;
  width: 100%;
  border-radius: $border-radius-md;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  color: inherit;
  background-color: white;
}
.dateSelector::-webkit-datetime-edit {
  color: black;
}
.button {
  padding: $spacing-xs 0;
}
.image {
  border-radius: $border-radius-lg;
}
.red {
  color: $red;
}

@media only screen and (max-width: $mobile-threshold) {
  .team {
    max-width: none;
  }
  .dateSelector {
    min-height: $spacing-lg;
  }
  .button {
    padding: $spacing-sm 0;
  }
  .container {
    max-width: 100vw;
  }
}
