@import '../styles/variables.scss';
.container {
  display: flex;
  border: $border;
  background-color: white;
  border-radius: 0.25rem;
  align-self: center;
  padding-right: 0.25rem;
  height: 2.5rem;
  width: 100%;
}

.icon {
  display: block;
  height: $font-size-md;
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  opacity: 0.4;
}

.input {
  border: none;
  background-color: white;
  font-size: $font-size-md;
  width: 100%;
}

.input:focus {
  outline: none;
}
