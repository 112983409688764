@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-sm;
  justify-content: center;
  padding: $spacing-xs;
  background-color: $white;
  border-radius: $border-radius-md;
}
.questionMenu {
  width: 35vw;
}
.large {
  width: 70vw;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
.editIcon {
  cursor: pointer;
  font-size: $font-size-lg;
  opacity: 0.5;
}

.openButton {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $accent-primary-translucent;
  margin: auto;
}
.formTitle {
  margin-bottom: $spacing-sm;
}
.spacer {
  height: $spacing-sm;
}

.openButton:hover {
  background-color: $accent-primary;
  cursor: pointer;
}

.addIcon {
  align-self: center;
  color: $white;
  height: 2rem;
  width: 2rem;
}
.icons {
  font-size: $font-size-md;
  margin-left: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  align-self: flex-start;
  font-size: $font-size-lg;
}

.closeIcon:hover {
  color: $text-secondary;
  cursor: pointer;
}
.noActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.text {
  word-break: break-all;
  text-wrap: wrap;
}
.title {
  margin: 0px;
  font-size: $font-size-lg;
}
.parent {
  border: $border-light;
  border-radius: $border-radius-md;
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  padding: $spacing-md;
  height: 100vh;
  justify-content: center;
  z-index: 100;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

.addButton {
  margin-top: auto;
  margin-left: auto;
}
.numberLimit {
  display: flex;
  width: 100%;
  gap: $spacing-md;
  margin-bottom: $spacing-xs;
}

.input {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  margin-bottom: $spacing-md;
}

.divider {
  border-top: solid $text-secondary-light 0.02rem;
  height: $spacing-sm;
}

.verticalDivider {
  border-left: solid $text-secondary-light 0.02rem;
}

.userContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deleteIcon {
  color: red;
}

.deleteIcon:hover {
  opacity: 0.5;
  cursor: pointer;
}

.warningIcon {
  color: $text-secondary;
}

.conditionalAction {
  margin-top: $spacing-md;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.conditionalActionButtons {
  margin-top: $spacing-sm;
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  justify-content: flex-end;
}

.conditionalActionSubtext {
  display: flex;
  flex-direction: row;
  gap: $spacing-sm;
  justify-content: flex-end;
  margin-top: $spacing-xs;
}

@media only screen and (max-width: $mobile-threshold) {
  .parent {
    width: 95vw;
  }

  .container,
  .questionMenu {
    width: 100%;
  }
  .addButton {
    margin-bottom: $spacing-lg;
  }
}
.selectOptions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hideRadio {
  display: none;
}
